@import '../../../assets/style/colors';

.list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin-bottom: 2em;
}

.item {
  position: relative;
  padding-left: 22px;
  margin-bottom: 0.769em;

  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.384;

  color: $palette-text-secondary-dark;

  &::before {
    content: '';
    width: 5px;
    height: 5px;

    border-radius: 50%;
    background-color: $palette-text-secondary-dark;

    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
  }

  &Completed {
    color: $palette-success;
  }

  &Completed::before {
    visibility: hidden;
    opacity: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    position: absolute;
    left: -4px;
    width: 20px;
    height: auto;
  }
}
