@import-normalize;
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap&subset=cyrillic');
@import 'colors';

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif !important;
}

html {
  font-size: 10px;
}

html body {
  margin: 0;
  padding: 0;
  direction: ltr;

  font-family: 'Open Sans', sans-serif !important;
  font-size: 1rem;
  line-height: 1.357;
  color: $palette-text-primary;

  background-color: $body-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 700px;
    }
  }
}

button {
  outline: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $palette-text-secondary-light;
  border-radius: 5px;
}

/* Cover autocomplete background styles */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
}
