@import '../../../../assets/style/mixins';

.root {
  width: 50px;
  height: 50px;
}

.error {
  color: $palette-error-dark;
}
