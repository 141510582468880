$header-fixed-height: 70px;
$header-fixed-height-tablet: 64px;
$header-fixed-height-phone: 50px;
$sider-fixed-width: 7.32vw;
$sider-min-width: 75px;
$sider-fixed-height-phone: 49px;

$main-font-size: 10px;

$main-border-radius: 4px;

$zIndex-header: 2000;
$zIndex-nav-bar: 2000;
$zIndex-modal: 3000;
$zIndex-call: 3050;
$zIndex-popover: 90;
$zIndex-nav: 100;
$zIndex-main-menu: 110;
$zIndex-secondary-menu-notification: 10;

$chat-editor-margin-top: var(--chat-editor-margin-top);
