@import '../../../../assets/style/mixins';

.error {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.615em 0;

  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $palette-error-dark;
  background-color: $palette-error-light;

  &Paragraph {
    margin-bottom: 0.615em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
