@import "../../../assets/style/colors";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 3.5em;

  button {
    margin: 0;
  }

  & > div {
    display: flex;
    align-items: center;

    min-width: 50px;
    min-height: 50px;
  }
}
