@import '../../../../../assets/style/colors';

.input {
  width: 40px;
  height: 44px;
  border: 1px solid $palette-stroke-primary-dark;
  border-radius: 4px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: $palette-text-primary;
}

.divider {
  width: 14px;
  height: 2px;
  background-color: $palette-text-primary;
}

.invalid {
  border-color: $palette-error;
}

.item {
  &:nth-last-child(n + 2) {
    margin-right: 12px;
  }
}
