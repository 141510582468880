.root {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  &Field {
    text-align: left;
  }
}

.email {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  cursor: pointer;
}
