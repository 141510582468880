.root,
.item {
  display: flex;
  flex-direction: column;
}

.root {
  row-gap: 20px;
  width: 100%;
  margin-bottom: 24px;
}

.item {
  row-gap: 8px;
}

.label,
.value {
  display: block;
  font-size: 14px;
}

.label {
  font-weight: 700;
}

.value {
  overflow-wrap: break-word;
}
