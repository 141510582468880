@import "src/assets/style/colors";

.icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;

  path {
    fill: $palette-text-secondary-dark;
  }
}
