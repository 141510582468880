@import "../../../../assets/style/colors";

[class].iconButton {
  padding: 1em;
  margin-right: 0.6em;

  svg {
    width: 16px;
    height: auto;
    color: $palette-text-secondary-dark;
  }
}
