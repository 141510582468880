@import '../../../../../assets/style/colors';

.root {
  display: flex;
  align-items: center;
}

.root.invalid {
  margin-bottom: 4px;
}

.invalidText {
  color: $palette-error;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  margin: 0;
}
