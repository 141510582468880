@import '../../../../assets/style/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &:nth-last-of-type(n + 2) {
    margin-bottom: 2.4em;
  }

  &:first-of-type {
    margin-top: 0.4em;
  }
}

.label {
  margin-bottom: 0.714em;
  font-size: 1.4rem;
  line-height: 1.357;
}

.rootFieldSmall :global(.MuiOutlinedInput-input) {
  padding: 10px 16px 10px;

  @include media-phone() {
    padding: 15px 16px;
  }
}

.rootField {
  width: 100%;
  font-size: $main-font-size;

  :global {
    .MuiInputLabel-root {
      font-size: 1.4em;

      &.Mui-focused {
        color: $palette-secondary;
        font-weight: 600;

        &.Mui-error {
          color: $palette-error;
        }
      }

      &.MuiInputLabel-outlined {
        transform: translate(14px, 17px) scale(1);
      }

      &.MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(16px, -6px) scale(0.75);
      }
    }

    .MuiInputBase-root {
      font-size: inherit;

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: $palette-stroke-primary-dark;
      }

      .MuiOutlinedInput-input {
        height: auto;
        padding: 0.928em 1.142em 0.857em;
        font-size: 1.4em;
        line-height: 1.357;
        color: $palette-text-primary;

        @include media-phone() {
          padding: 0.937em 1em;
          font-size: 1.6em;
          line-height: 1.25em;
        }

        &:disabled {
          color: $palette-text-secondary-dark !important;
          background-color: rgba($palette-text-secondary-light-rgb, 0.5) !important;
        }
      }

      .MuiOutlinedInput-inputAdornedEnd {
        padding-right: 0;
      }

      .MuiOutlinedInput-inputMultiline {
        padding: 0;
        line-height: 22px;
      }

      .MuiOutlinedInput-multiline {
        max-height: none;
        height: unset;
        padding: 12px 16px;
      }

      .MuiInputAdornment-positionEnd {
        margin-left: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $palette-stroke-primary-dark;
      }

      &.Mui-focused.Mui-error,
      &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid $palette-error;
        }
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $palette-secondary;
      }
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .MuiFormHelperText-root {
      font-size: 1.2em;
      line-height: 1.333;

      &.Mui-error {
        color: $palette-error;
      }
    }
  }
}

.valueLength {
  position: absolute;
  right: 0;
  bottom: -18px;
  color: $palette-text-secondary-dark;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  &Success {
    color: $palette-success;
  }

  &Fail {
    color: $palette-error;
  }
}

.errorLabel {
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 4px;
  color: $palette-error;
  cursor: default;

  font-size: 12px;
  line-height: 16px;
}
