@import '../../../../assets/style/mixins';

// [class].root is equal to .ant-modal
[class].root {
  position: relative;
  padding: 0;
  font-size: $main-font-size;

  @include media-phone() {
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: calc(100vw - 16px) !important;
    max-width: 530px;
    padding: 0 4px;
    margin: 0 auto;
  }

  .closeIcon {
    width: 30px;
    height: auto;
    color: $palette-text-primary;
  }

  :global {
    .ant-modal-content {
      position: static;
      padding: 6em 6em 0;
      background-color: $white;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);

      @include media-phone() {
        position: relative;
        height: 100%;
        padding: 5em 2em 0;
        max-height: 80vh;
        overflow: auto scroll;
        box-shadow: unset;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        @supports (max-height: min(0px)) {
          max-height: unquote(
            'min(80vh, calc(80vh - env(safe-area-inset-bottom) - env(safe-area-inset-top)))'
          );
        }
      }
    }

    .ant-modal-header {
      border: none;
      padding: 0;
      margin-bottom: 10px;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 2.6em;
      line-height: 1.346em;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: $palette-text-primary;
      gap: 32px;

      @include media-phone() {
        gap: 24px;
        font-size: 1.8em;
        line-height: 1.388em;
      }
    }

    .ant-modal-body {
      padding: 0 0 60px;

      @include media-phone() {
        padding-bottom: 50px;
      }
    }

    .ant-modal-close-x {
      width: 68px;
      height: 68px;
      line-height: 68px;
    }
  }
}

@include media-phone() {
  :global(.ant-modal-root .ant-modal-wrap) {
    overflow: unset;
  }
}

[class]:global(.MuiTooltip-popper) {
  z-index: $zIndex-modal + 50;
}

[class]:global(.ant-modal) {
  color: $palette-text-primary;
}
