.wrapper {
  color: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper .icon svg {
  width: 20px;
  height: 20px;
}

.left,
.right {
  display: inline-flex;
}

.left {
  margin-right: 4px;
}

.right {
  margin-left: 4px;
}
