@import 'src/assets/style/mixins';

.root {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: 0;

  color: $palette-secondary;
  cursor: pointer;

  svg {
    height: auto;
  }

  &.xs svg {
    width: 14px;
  }

  &.sm svg {
    //TODO: CHANGE THE WIDTH when UI will be ready
    width: 18.68px;
  }

  &.md svg {
    width: 24px;
  }

  &.lg svg {
    width: 28px;
  }

  &.xl svg {
    width: 36px;
  }

  &:hover {
    color: $palette-secondary;
  }

  &:disabled {
    cursor: not-allowed;
    color: $palette-disable;
  }
}
