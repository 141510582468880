@import '../../../assets/style/mixins';

.root {
  width: 100%;
  margin: 70px 0 2.4em;
}

.header {
  position: relative;
  padding: 0 80px;
}

.backButton {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.title {
  margin: 0;
  text-align: center;
  font-size: 2rem;
  line-height: 1.35;
  font-weight: 700;
  color: $palette-text-primary;
}

.login {
  margin: 28px 0 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}

.root .steps {
  margin-top: 34px;
}

.link {
  @include link(0);
  font-size: inherit;
}

@include media-phone() {
  .root {
    margin-top: 20px;
  }
}
