@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: start;
}

.description {
  font-style: normal;
  text-align: center;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px;
  color: $palette-text-primary;
  margin-bottom: 28px;

  &.hasSubDescription {
    font-weight: 600;
    margin-bottom: 20px;
  }
}

.resend {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 37px;
}

.buttonLink {
  margin: 0;
  display: block;
  padding: 0;
  background: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border: none;
  border-bottom: 1px dashed $palette-secondary;
  color: $palette-secondary;
}

.code {
  margin-bottom: 22px;
}

[class].button {
  @include button();
  margin: 0;

  @include media-desktop() {
    margin: 0;
  }
}
