@import '../../../assets/style/mixins';

$header-fixed-height: 50px;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(#{$header-fixed-height} + 30px) 14px 30px 14px;
  background-color: $palette-info-light;
  min-height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $header-fixed-height;
  background-color: $palette-primary;
  padding: 10px 20px;

  .logo {
    height: 30px;
  }
}

.card {
  width: 100%;
  padding: 60px 20px;
  border-radius: 4px;
  background-color: $white;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    width: 32px;
    height: 32px;
    color: $palette-secondary;
  }

  .title {
    margin-top: 12px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 18px;
  }

  .button {
    width: 100%;
    max-width: 330px;
    margin-top: 20px;
  }
}

.dividerContainer {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 100%;
  margin: 24px 0 20px 0;

  .divider {
    flex: 1;
    height: 1px;
    background-color: $palette-stroke-primary;
    border: none;
    margin: 0 10px;
  }

  .orText {
    font-size: 14px;
    color: $palette-text-primary;
    font-weight: 600;
  }
}

.download {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  .downloadButton {
    display: flex;
    align-items: center;
    padding: 11px;
    border: 2px solid $palette-text-primary; 
    border-radius: 4px;
    max-width: 330px;
    width: 100%;
    justify-content: center;
  }

  .appleIcon,
  .googleIcon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .downloadText {
    font-size: 16px;
    color: $palette-text-primary;
    font-weight: 600;
    margin: 0;
  }
}

