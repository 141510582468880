@import '../../../../assets/style/mixins';

@mixin root($height) {
  width: 100%;

  .inputWrapper {
    font-size: 1.3rem;
    line-height: 1.307;

    color: $palette-text-primary;

    & > div {
      margin: 0;
      padding: 0;
    }
  }

  .control {
    $border-radius: 4px;

    will-change: transform;
    position: relative;

    box-sizing: border-box;
    border: 1px solid $palette-stroke-primary-dark;
    border-radius: $border-radius;

    background: none;
    box-shadow: 0 4px 10px transparent;
    height: $height;
    max-height: $height;

    &.isMulti {
      max-height: unset;
    }

    &.focused {
      border-color: transparent;
    }

    .baseBorder {
      margin: 0;
      padding: 11px 14px 12px;
      width: 100%;
      display: flex;
      border: 2px solid transparent;
      border-radius: $border-radius;
      max-height: $height;
    }

    &.focused .baseBorder {
      border-color: $palette-secondary;
    }

    &.isMulti .baseBorder {
      max-height: unset;
    }

    .label {
      position: absolute;
      left: 15px;
      font-size: 1.4rem;
      margin: 0 0 0 0.5em;
      padding-left: 0.5em;
      padding-right: 0.5em;
      transition: all 0.3s;

      color: $palette-text-secondary-dark;
    }

    &.selected .label,
    &.focused .label {
      background: $white;
      font-size: 1.15rem;
      transition: transform 0.3s;
      transform: translate(0, -26px);
    }

    &.focused .label {
      font-weight: 600;
      color: $palette-secondary;
    }
  }

  .singleValue {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    margin: 0;
    top: 40%;

    color: $palette-text-primary;
  }

  .multiValue {
    height: 25px;
    padding: 0 0.428em;
    font-weight: 600;
    line-height: 1.785;
    border-radius: 20px;

    div:last-child {
      cursor: pointer;
    }
  }

  .multiValue:hover {
    color: currentColor;
    background-color: $palette-stroke-primary-light;
  }

  .value {
    padding: 0;
    height: 100%;
  }

  .placeholder {
    display: none;
  }

  .option {
    display: flex;
    background-color: $white;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    color: $palette-text-primary;
    padding: 0.428em 1.142em;

    &Focused {
      background-color: $palette-secondary-hover;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: $palette-secondary-hover;
    }

    &Selected {
      color: $palette-secondary;

      &:hover {
        color: $palette-text-primary;
      }
    }
  }

  .iconWrapper {
    display: inline-flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $palette-primary;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
  }

  .icon {
    width: 10px;
    height: 10px;
    color: $palette-primary;
  }

  .indicator {
    padding: 0;

    svg {
      color: $palette-text-primary;
    }
  }

  .menu {
    z-index: 3;
    margin-top: 1px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 5px 5px;

    &List {
      padding: 1em 0;
    }
  }

  &Disable {
    cursor: not-allowed;

    & .control {
      background-color: $palette-select-disable;
    }

    .indicator svg path{
      fill: $palette-select-icon-disable;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &:nth-last-of-type(n + 2) {
    margin-bottom: 2.4em;
  }
}

.topLabel {
  margin-bottom: 0.714em;
  font-size: 1.4rem;
}
.root {
  @include root(44px);
}

.smallSelect {
  @include root(40px);

  .control {
    .baseBorder {
      padding: 8px 14px 8px;
    }
  }
}

.highlightText {
  background-color: inherit;
  font-weight: bold;
}

.noOptionMessage {
  padding: 0.357em;
  font-size: 1.4rem;
  text-align: center;
  cursor: default;
}
