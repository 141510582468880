@import '../../../../assets/style/mixins';

[class].root {
  max-width: 152px;
  width: 100%;
  margin: 0 auto;

  :global {
    .ant-steps-item-icon,
    .ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
      display: inline-block;
      font-size: 13px;
      line-height: 26px;
      font-weight: bold;
      color: $white;
      margin-right: 0;
      cursor: default;
      width: 28px;
      height: 28px;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
      background-color: $palette-primary;
      border-color: $palette-primary;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
      border: 2px solid $palette-stroke-primary-dark;
    }

    .ant-steps-item-title {
      padding-right: 8px;
    }

    .ant-steps-item-title::after {
      top: 14px;
      height: 2px;
      background: $palette-stroke-primary-light;
    }

    .ant-steps-item {
      display: inline-flex;
      padding-left: 8px;
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: $palette-primary;
    }

    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:not(:first-child) {
      padding-left: 8px;
    }
  }
}

.icon {
  width: 28px;
  height: 28px;
  color: $palette-primary;
}
