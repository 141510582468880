@import '../../../../assets/style/colors';


:global(.MuiCheckbox-colorSecondary.Mui-checked).root,
:global(.MuiCheckbox-colorPrimary.Mui-checked).root {
  color: $palette-primary !important;
}

:global(.MuiCheckbox-colorSecondary).root,
:global(.MuiCheckbox-colorPrimary).root {
  color: $palette-text-secondary !important;
}


