@import '../../../../../assets/style/mixins';

.form {
  @include form();
}

[class].button {
  @include button();
}

.link {
  @include link();
}
