@import '../../../../../assets/style/colors';

.circle {
  &:global([class].default) {
    stroke: $white;
  }

  &:global([class].secondary) {
    stroke: $palette-secondary;
  }
}
