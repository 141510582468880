@import '../../../../../assets/style/mixins';

.promo,
.email {
  font-size: 1.4rem;
  line-height: 1.357;
  text-align: center;
}

.email {
  display: flex;
  align-items: center;
  margin-top: 1.428em;
  font-weight: 600;
}

[class].button {
  @include button();
}

.link {
  @include link();
}

@include media-phone() {
  .promo {
    margin-top: 20px;
  }
}
