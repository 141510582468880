@import '../../../assets/style/mixins';

.form {
  @include form();
}

[class].button {
  @include button();
}

[class].link {
  @include link();
}

.email {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 28px;
  text-align: center;
}

.description {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 32px;
}

.buttons {
  padding-top: 32px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;

  > * {
    margin-top: 0 !important;
  }
}
