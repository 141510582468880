@import '../../../assets/style/mixins';

$media-phone: 480;

[class].Alerts {
  font-size: 14px;
  max-width: 420px;
  width: unset;
  min-width: 240px;

  &:global(.Toastify__toast-container--top-right) {
    right: 16px;
  }

  :global {
    .Toastify__toast {
      min-width: 420px;
      min-height: 54px;
      padding: 17px 52px 18px 66px;
      box-shadow: none;
      cursor: default;

      .ToastCustomCloseButton {
        width: 20px;
        height: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 30px;
        width: 24px;
        height: 24px;

        transform: translateY(-50%);
        background: center / cover no-repeat;
      }

      &-body {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }

      &--success {
        &::before {
          background-color: $palette-success-dark;
          mask-image: url('../../../assets/icons/CheckCircleOutline.svg');
        }

        background-color: $palette-success-light;
        color: $palette-success-dark;

        .ToastCustomCloseButton * {
          fill: $palette-success-dark;
        }
      }

      &--info {
        &::before {
          background-color: $palette-info-dark;
          mask-image: url('../../../assets/icons/Info.svg');
        }

        background-color: $palette-info-light;
        color: $palette-info-dark;

        .ToastCustomCloseButton * {
          fill: $palette-info-dark;
        }
      }

      &--warning {
        &::before {
          background-color: $palette-warning-dark;
          mask-image: url('../../../assets/icons/Warning.svg');
        }

        background-color: $palette-warning-light;
        color: $palette-warning-dark;

        .ToastCustomCloseButton * {
          fill: $palette-warning-dark;
        }
      }

      &--error {
        &::before {
          background-color: $palette-error-dark;
          mask-image: url('../../../assets/icons/AlertCircleOutline.svg');
        }

        background-color: $palette-error-light;
        color: $palette-error-dark;

        .ToastCustomCloseButton * {
          fill: $palette-error-dark;
        }
      }
    }

    .ToastCustomCloseButton {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}

@include media-phone($media-phone) {
  [class].Alerts {
    min-width: unset;
  }
}

@include media-phone() {
  [class].Alerts :global(.Toastify__toast) {
    min-width: unset;
    max-width: 300px;
    margin-left: auto;
  }
}
