@import '../../../../assets/style/mixins';

.form {
  row-gap: 32px;

  @include form();
}

.buttons {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.info {
  background-color: $palette-info-light;
  color: $palette-info-dark;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
