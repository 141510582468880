@import '../../../../../assets/style/colors';

.btn {
  margin: 0;
  padding: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  border: none;

  &:hover {
    text-decoration: none;
  }
}

.content {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: $palette-secondary;
}

.next {
  margin-left: 8px;
  transform: rotate(180deg);
}

.prev {
  margin-right: 8px;
}
