@import '../../../../assets/style/colors';

[class].checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2.4em;

  &Label {
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.357;
  }

  & :global(.MuiFormControlLabel-root) {
    margin: 0;
  }

  & :global(.MuiButtonBase-root) {
    padding: 0;
    margin-right: 1.1rem;
  }

  & :global(.MuiSvgIcon-root) {
    font-size: 2.4rem;
  }

  &Error :global(.MuiSvgIcon-root) {
    color: $palette-error;
  }
}
