@import '../../../assets/style/mixins';

.root {
  display: flex;
  height: 100vh;
  overflow: hidden;
  min-height: 670px;
}

.wrapper {
  overflow: hidden;
  display: flex;
  margin: 40px auto;
  align-items: center;
}

.holder {
  display: flex;
  width: 90vw;
  min-width: 300px;
  max-width: 920px;
  overflow: hidden;
  border-radius: 10px;
  min-height: 590px;
  max-height: 100%;
  border: 1px solid $palette-stroke-primary;
}

.loading {
  width: 100%;
  padding: 91px 0;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  flex-shrink: 1;
  gap: 20px;

  padding: 0 1.6em 4em 1.6em;

  background: $white;

  @include media-not-phone-portrait() {
    padding: 0 4em 4em 4em;
  }
}

.sidebar {
  width: 50%;
  display: flex;
  padding: 50px;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: $palette-primary;
  position: relative;

  h1 {
    color: $white;
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 12px;
    text-align: center;
  }
}

.footer {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 592px;
  background: url('../../../assets/images/background.png') 50% 100% no-repeat;

  &Wrapper {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@include media-phone() {
  .root {
    min-height: unset;
    align-items: start;
  }

  .wrapper {
    margin: 16px auto;
  }

  .holder {
    min-height: unset;
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: 193px;
    padding: 20px;

    h1 {
      font-size: 16px;
    }
  }

  .bg {
    height: 100%;
    min-height: unset;
    background-size: 125px;
    bottom: -25%;
    top: auto;
  }

  .logo {
    width: 116px;
  }
}
