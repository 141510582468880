@import './colors';
@import './mediaVariables.module';
@import './variables';

@mixin field($width, $marginBottom: 0) {
  width: $width;
  margin-bottom: $marginBottom;
}

@mixin button($marginTop: 2em) {
  font-size: 1.6rem;
  margin-top: $marginTop;
  padding: 1em 3.75em;
  line-height: 1.214;

  @include media-desktop() {
    font-size: 1.4rem;
    margin-top: 2.285em;
    padding: 1em 2.857em 1.071em;
  }
}

@mixin link($marignTop: 1.666em) {
  margin-top: $marignTop;
  font-size: 14px;
  line-height: 1.333;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px dashed $palette-secondary;

  color: $palette-secondary;

  &:hover {
    text-decoration: none;
    color: $palette-secondary-light;
    border-bottom-color: rgba($palette-secondary-light, 0.4);
  }

  &:active {
    text-decoration: none;
    color: $palette-secondary-dark;
    border-bottom-color: rgba($palette-secondary-dark, 0.4);
  }
}

@mixin form() {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &Fieldset {
    width: 100%;
  }
}

@mixin media-not-phone-portrait() {
  @media (min-width: $max-phone-portrait-width + 'px') {
    @content;
  }
}

@mixin media-tablet($maxWidth: $media-desktop-min-width) {
  @media screen and (max-width: $media-desktop-min-width + 'px'),
    (max-device-width: $maxWidth + 'px') {
    @content;
  }
}

@mixin media-desktop() {
  @media (min-width: $media-desktop-min-width + 'px') {
    @content;
  }
}

@mixin modalCommonComponent() {
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: $main-font-size;
    margin-top: 1.8em;

    @include media-phone() {
      margin-top: 1em;
    }

    &Icon {
      margin-bottom: 2.8em;

      @include media-phone() {
        margin-bottom: 2em;
      }
    }

    &Title {
      margin: 0;
      font-size: 1.4em;
      line-height: 1.571;
      color: $palette-text-primary;
    }

    &Text {
      margin: 0;
      font-size: 1.4em;
      line-height: 1.571;
    }

    &Error {
      margin-bottom: 1.5em;
    }

    &Button {
      margin-top: 2em;

      @include media-tablet() {
        margin-top: 1.75em;
      }

      @include media-phone() {
        margin-top: 1.25em;
      }
    }

    &Link {
      margin-top: 1.428em;
      padding: 0;
      border: 0;
      background: none;

      @include link();
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1.357;
    }
  }
}

@mixin media-phone(
  $maxWidth: $media-tablet-min-width - 1,
  $maxWidthLandscape: $media-mobile-max-width-landscape
) {
  @media screen and (max-device-width: $maxWidth + 'px'),
    (max-width: $maxWidth  + 'px'),
    (max-width: $maxWidthLandscape + 'px') and (orientation: landscape),
    (max-device-width: $maxWidthLandscape + 'px') and (orientation: landscape) {
    @content;
  }
}
