@import '../../../../../assets/style/colors';

.error,
.time {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.time {
  color: $palette-warning;
}

.error {
  color: $palette-error;
}
